import Navi from "./navi";
import Branch from "./branches";
import { Container, Row, Col, Accordion } from 'react-bootstrap';
import arrow from '../Images/arrow.png';
import cour1 from "../Images/cour1.PNG";
import cour2 from "../Images/cour2.PNG";
import cour3 from "../Images/cour3.PNG";
import cour4 from "../Images/cour4.PNG";
import "bootstrap-icons/font/bootstrap-icons.css";

function Techie() {
    return (
        <div className="a11">
            <p className="a27">Who’s this for?</p>
            <Container>
                <Row>
                    <Col md={4}>
                        <div className="a29">
                            <img src={arrow} className="a28" />
                            <p>Students/Graduates wanting to explore the vast field of marketing</p>
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className="a29">
                            <img src={arrow} className="a28" />
                            <p>Working professionals with 0-2 years of experience aiming</p>
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className="a29">
                            <img src={arrow} className="a28" />
                            <p>Professionals wanting to switch to software career</p>
                        </div>
                    </Col>
                </Row>
                <div className="text-center">
                    <a href='https://wa.me/918667641134?text=Hii..' target='_blank'>
                        <button className="a30">Register Now <i class="bi bi-arrow-up-right"></i></button>
                    </a>
                </div>
            </Container><br /><br />
            <div className="a31">
                <br /><br /><br />
                <Container>
                    <h1 className="a32">COURSE WE OFFER:</h1>
                    <p className="a32">Our job-oriented course gives you the comfortability & Support at the right time</p>
                    <br /><br />
                    <Row>
                        <Col md={6}>
                            <div id="one">
                                <img src={cour1} className="a33" />
                                <h4 className="a34">Mobile full stack development:</h4>
                                <p className="a32">This course involves creating applications that run on mobile devices.
                                    Developers working on mobile full-stack development need to have expertise in both front-end
                                    and back-end technologies, as well as a good understanding of mobile platforms and their specific requirements.</p>
                            </div>
                        </Col>

                        <Col md={6}>
                            <div id="one">
                                <img src={cour2} className="a33" />
                                <h4 className="a34">Web Full-stack development:</h4>
                                <p className="a32">This course involves a well-rounded skill set and the ability to work
                                    on both the client and server sides of a web application. It's important for developers to stay
                                    updated on the latest technologies and best practices in both front-end and back-end development.</p>
                            </div>
                        </Col>

                        <Col md={6}>
                            <div id="one">
                                <img src={cour3} className="a33" />
                                <h4 className="a34">Mobile front-end development:</h4>
                                <p className="a32">This course has a dynamic field, and staying updated with the latest
                                    technologies and best practices is crucial for creating successful and engaging mobile applications.
                                </p>
                            </div>
                        </Col>

                        <Col md={6}>
                            <div id="one">
                                <img src={cour4} className="a33" />
                                <h4 className="a34">Web front-end development:</h4>
                                <p className="a32">This course involves creating the user interface and user experience of a website or web application.
                                    It focuses on the design, layout, and interactivity that users see and interact with in their browsers.</p>
                            </div>
                        </Col>

                    </Row>


                </Container>

            </div>

            <Container>
                <br /><br />
                <h1>Frequently Asked Questions</h1>
                <br /><br />
                <Row>
                    <Col md={6}>
                        <Accordion className="a35">
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>What is One on one training?</Accordion.Header>
                                <Accordion.Body>
                                    GT Software college offers personalized attention to the Students such as when
                                    addressing specific skill gaps, mastering complex concepts, or achieving personal goals.
                                    It can be more intensive and focused, leading to more effective and efficient learning
                                    outcomes for the individual.
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Col>
                    <Col md={6}>
                        <Accordion className="a35">
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>Duration and timings of the course?</Accordion.Header>
                                <Accordion.Body>
                                    In GT Software College we have full day class from 9:30 am to 5:30 pm and the duration of the course is 3 to 4 months.
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Col>
                    <Col md={6}>
                        <Accordion className="a35">
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>Online classes available?</Accordion.Header>
                                <Accordion.Body>
                                    No. Online classes are not encouraged in GT. Strictly we avoid online classes.

                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Col>
                    <Col md={6}>
                        <Accordion className="a35">
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>EMI options available?</Accordion.Header>
                                <Accordion.Body>
                                    EMI option is also available in GT Software College
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Col>
                    <Col md={6}>
                        <Accordion className="a35">
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>Why is this course 100% in-person and not online?</Accordion.Header>
                                <Accordion.Body>
                                We think that hands-on programs need face-to-face meetings. Although students can learn on their own time, 
                                all live project meetings are held in person. Project presentations will be helpful in attending interviews.
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Col>
                    <Col md={6}>
                        <Accordion className="a35">
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>Do you provide a degree or diploma?</Accordion.Header>
                                <Accordion.Body>
                                No, we don’t provide degrees or diplomas. We focus on giving you essential programming skills, Problem solving techniques 
                                and job prospects to excel as a developer or potential founder in the startup sector.
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Col>
                </Row>
                <br /><br />
            </Container>
        </div>
    );
}

export default Techie;