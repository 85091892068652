
import './App.css';
import Banner from './Components/banner';
import Branch from './Components/branches';
import Courses from './Components/courses';
import Fullstack from './Components/fullstack';
import Help from './Components/help';
import Mobweb from './Components/mobweb';
import Navi from './Components/navi';
import Start from './Components/start';
import Techie from './Components/techie';
import whatsapp from './Images/whatsapp.png';

function App() {
  return (
    <>

<a href='https://wa.me/918667641134?text=Hii..' target='_blank'>
    <img src={whatsapp} className='n33'/>
    </a>
      <Navi/>
      <Banner/>

      <Techie/>
      <Start/>
      <Help/>
      <Fullstack/>
      <Branch/>

     
      

      {/* <Mobweb/> */}
      {/* <Courses/> */}
    </>
  );
}

export default App;
