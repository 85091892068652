import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Button, Row, Col, Card } from 'react-bootstrap';
import "bootstrap-icons/font/bootstrap-icons.css";


function Banner(){
    return(
        <>
            <div className="py-5">
            <Container>
                <Row>
                    <Col md={6}>
                    <h1 className="a23">Cultivate an authentic employee-like experience through our Full-day classes</h1><br/>

                    <Row>
                        <Col xs={1}><i class="bi bi-person-check-fill a25"></i></Col>
                        <Col><p>Our personalized <b>Full day, one-on-one training</b> offers a comprehensive depth of knowledge.</p></Col>
                    </Row>
                    <Row>
                        <Col xs={1}><i class="bi bi-slash-square-fill a25"></i></Col>
                        <Col><p> Embrace a real-world learning experience in a software company, <b> Devoid of traditional trainers and classrooms</b>, akin to a fresh recruit</p></Col>
                    </Row>
                    
                <br/>
                    <div className="text-center"><Button variant="danger" size="lg" href="https://www.instagram.com/gt_software_academy/">Visit Insta page <i className="bi bi-instagram"></i></Button></div>
                    </Col>

                    <br/>

                    <Col md={6}>
                    <br/>
                    <Card className="mx-4 shadow">
                        <Card.Body>
                            <h1 className="a25">5 Things</h1>
                            <h2 className="">We are unique from others</h2>
                            <ul className="">
                                <li>Personalized one-on-one training</li>
                                <li>100% Practical classes</li>
                                <li>No trainers, Only professionals</li>
                                <li>No syllabus based curriculum</li>
                                <li>We provide expert guidance to empower you in developing your own applications, such as those akin to Netflix, Flipkart, CRM, ERP, and more.</li>
                            </ul>
                        </Card.Body>
                    </Card>
                    </Col>
                </Row>
                
                   </Container>
            </div>
        </>
    );
}

export default Banner;