import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Card } from 'react-bootstrap';
import "bootstrap-icons/font/bootstrap-icons.css";

function Courses() {
    return (
        <>
            <Container className="my-5">

                <h2 className="text-center">OUR COURSE MODULE:</h2>
                <p className="b30">Our mobile app development & web app development courses are structured with advanced technologies
                    which infringes career focused training to each and every candidate.</p>
                <h4 className="mb-3 b18">Mobile App Plans:</h4>

                <Row>
                    <Col md={4}>
                        <Card className="mt-3 b21">
                            <Card.Body>
                                <div className="b16">
                                    <span className="b12">Mobile App (Plan 1)</span>
                                    <h5 className="mt-4">Mobile Front End - OTT Platform</h5>
                                </div>


                                <Row>
                                    <Col><p className="b14"> <i class="bi bi-hourglass-split a13"></i> 45 Days</p></Col>
                                    <Col><p className="b14"> <i class="bi bi-clock-fill a13"></i> 9 AM - 5 PM</p></Col>
                                </Row>

                                <hr />
                                <Row>
                                    <Col xs={1}><i class="bi bi-google-play b15"></i></Col>
                                    <Col><p className="b17">Technologies :- Flutter, Dart, Play Store, Git</p></Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={4}>
                        <Card className="mt-3 b21">
                            <Card.Body>
                                <div className="b16">
                                    <span className="b12">Mobile App (Plan 2)</span>
                                    <h5 className="mt-4">Mobile Full Stack - OTT Platform</h5>
                                </div>

                                <Row>
                                    <Col><p className="b14"> <i class="bi bi-hourglass-split a13"></i> 2 Months</p></Col>
                                    <Col><p className="b14"> <i class="bi bi-clock-fill a13"></i> 9 AM - 5 PM</p></Col>
                                </Row>
                                <hr />
                                <Row>
                                    <Col xs={1}><i class="bi bi-google-play b15"></i></Col>
                                    <Col><p className="b17"> Technologies :- Flutter, Dart, Dotnet Core, MS SQL, Play Store, Git</p></Col>
                                </Row>


                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={4}>
                        <Card className="mt-3 b21">
                            <Card.Body>
                                <div className="b16">
                                    <span className="b12">Mobile App (Plan 3)</span>
                                    <h5 className="mt-4">Mobile Full Stack - OTT Platform & E-Commerce</h5>
                                </div>
                                <Row>
                                    <Col><p className="b14"> <i class="bi bi-hourglass-split b13"></i> 3 Months</p></Col>
                                    <Col><p className="b14"> <i class="bi bi-clock-fill b13"></i> 9 AM - 5 PM</p></Col>
                                </Row>
                                <hr />
                                <Row>
                                    <Col xs={1}><i class="bi bi-google-play b15"></i></Col>
                                    <Col><p className="b17">Technologies :- Flutter, Dart, Node JS, Mongo DB, AWS, Play Store, Git</p></Col>
                                </Row>

                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <h4 className="mt-3 b18">Web Application Plans:</h4>
                <Row>
                    <Col md={4}>
                        <Card className="mt-3 b21">
                            <Card.Body>
                                <div className="b16">
                                    <span className="b12">Web App (Plan 1)</span>
                                    <h5 className="mt-4">Web Front End - ECommerce</h5>
                                </div>


                                <Row>
                                    <Col><p className="b14"> <i class="bi bi-hourglass-split b13"></i> 45 Days</p></Col>
                                    <Col><p className="b14"> <i class="bi bi-clock-fill b13"></i> 9 AM - 5 PM</p></Col>
                                </Row>

                                <hr />
                                <Row>
                                    <Col xs={1}><i class="bi bi-google-play b15"></i></Col>
                                    <Col><p className="b17">Technologies :- Angular (HTML, CSS, Bootstrap, Typescript), AWS, Git </p></Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={4}>
                        <Card className="mt-3 b21">
                            <Card.Body>
                                <div className="b16">
                                    <span className="b12">Web App (Plan 2)</span>
                                    <h5 className="mt-4">Web Front End - ECommerce</h5>
                                </div>

                                <Row>
                                    <Col><p className="b14"> <i class="bi bi-hourglass-split b13"></i> 45 Days</p></Col>
                                    <Col><p className="b14"> <i class="bi bi-clock-fill b13"></i> 9 AM - 5 PM</p></Col>
                                </Row>
                                <hr />
                                <Row>
                                    <Col xs={1}><i class="bi bi-google-play b15"></i></Col>
                                    <Col><p className="b17"> Technologies :- React (HTML, CSS, React-Bootstrap, Javascript), AWS, Git </p></Col>
                                </Row>


                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={4}>
                        <Card className="mt-3 b21">
                            <Card.Body>
                                <div className="b16">
                                    <span className="b12">Web App (Plan 3)</span>
                                    <h5 className="mt-4">Web Full Stack - E-Commerce</h5>
                                </div>
                                <Row>
                                    <Col><p className="b14"> <i class="bi bi-hourglass-split b13"></i> 2 Months</p></Col>
                                    <Col><p className="b14"> <i class="bi bi-clock-fill b13"></i> 9 AM - 5 PM</p></Col>
                                </Row>
                                <hr />
                                <Row>
                                    <Col xs={1}><i class="bi bi-google-play b15"></i></Col>
                                    <Col><p className="b17">Technologies :- Angular (HTML, CSS, Bootstrap, Typescript), Dotnet Core, MS SQL, AWS, Git</p></Col>
                                </Row>

                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={4}>
                        <Card className="mt-3 b21">
                            <Card.Body>
                                <div className="b16">
                                    <span className="b12">Web App (Plan 4)</span>
                                    <h5 className="mt-4">Web Full Stack - E-Commerce</h5>
                                </div>
                                <Row>
                                    <Col><p className="b14"> <i class="bi bi-hourglass-split b13"></i> 2 Months</p></Col>
                                    <Col><p className="b14"> <i class="bi bi-clock-fill b13"></i> 9 AM - 5 PM</p></Col>
                                </Row>
                                <hr />
                                <Row>
                                    <Col xs={1}><i class="bi bi-google-play b15"></i></Col>
                                    <Col><p className="b17">Technologies :- React (HTML, CSS, React-Bootstrap, Javascript), Node JS, Mongo DB, AWS, Git</p></Col>
                                </Row>

                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={4}>
                        <Card className="mt-3 b21">
                            <Card.Body>
                                <div className="b16">
                                    <span className="b12">Web App (Plan 5)</span>
                                    <h5 className="mt-4">Web Full Stack - ECommerce & CRM</h5>
                                </div>


                                <Row>
                                    <Col><p className="b14"> <i class="bi bi-hourglass-split b13"></i> 3 Months</p></Col>
                                    <Col><p className="b14"> <i class="bi bi-clock-fill b13"></i> 9 AM - 5 PM</p></Col>
                                </Row>

                                <hr />
                                <Row>
                                    <Col xs={1}><i class="bi bi-google-play b15"></i></Col>
                                    <Col><p className="b17">Technologies :- Angular (HTML, CSS, Bootstrap, Type Script), Dotnet Core, MS Sql, AWS, Git </p></Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={4}>
                        <Card className="mt-3 b21">
                            <Card.Body>
                                <div className="b16">
                                    <span className="b12">Web App (Plan 6)</span>
                                    <h5 className="mt-4">Web Full Stack - ECommerce & CRM</h5>
                                </div>


                                <Row>
                                    <Col><p className="b14"> <i class="bi bi-hourglass-split b13"></i> 3 Months</p></Col>
                                    <Col><p className="b14"> <i class="bi bi-clock-fill b13"></i> 9 AM - 5 PM</p></Col>
                                </Row>

                                <hr />
                                <Row>
                                    <Col xs={1}><i class="bi bi-google-play b15"></i></Col>
                                    <Col><p className="b17">Technologies :- React (HTML, CSS, React-Bootstrap, Javascript), Node JS, Mongo DB, AWS, Git </p></Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>

                </Row>

            </Container>
        </>
    );
}

export default Courses;